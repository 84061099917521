import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Heading, Image } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_RESEPSI_TIME,
} from '@/constants';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_FLOWER_2A } from '@/constants/assets';

import { ENABLE_LIVE_STREAMING } from '@/constants/feature-flags';
import { TITLE_SECTION_STYLES } from '@/constants/typography'

import useInvitation from '@/hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%">
        <WithAnimation>
          <Heading
            {...TITLE_SECTION_STYLES}
            textTransform="lowercase"
            marginTop={isInvitation ?"20px" :"-12px"} 
          >
            Live Streaming
          </Heading>
        </WithAnimation>
        {ENABLE_LIVE_STREAMING && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              margin="18px 0 32px"
              dangerouslySetInnerHTML={{
                __html: `Akad | ${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {/* Live stream section */}
        <Box pos="absolute" right="-20px" bottom="600px" zIndex="5" transform="rotateY(180deg)">
          <WithAnimation right>
            <Image src={ASSETS_FLOWER_2A} width="110px" style={{ transform: 'rotate(36deg)' }} />
          </WithAnimation>
        </Box>
        <Box pos="absolute" left="-20px" bottom="130px" zIndex="5" transform="rotateY(180deg)">
          <WithAnimation left>
            <Image src={ASSETS_FLOWER_2A} width="110px" style={{ transform: 'rotate(36deg)' }} />
          </WithAnimation>
        </Box>
        <WithAnimation right>
          <Center>
          <Box pos="relative" width="85%">
            <AspectRatio maxW="560px" ratio={16 / 9}>
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                style={{ borderRadius: '16px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
          </Box>
          </Center>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="12px 32px 0 32px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="32px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                {txt.open[lang]}
              </Button>
            </Link>
          </Center>
        </WithAnimation>
        {ENABLE_LIVE_STREAMING && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              margin="12px 0 32px"
              dangerouslySetInnerHTML={{
                __html: `Reception | ${WEDDING_AKAD_FULLDATE} | ${WEDDING_RESEPSI_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        <WithAnimation left>
          <Center>
          <Box pos="relative" width="85%">
            <AspectRatio maxW="560px" ratio={16 / 9}>
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={`https://www.youtube.com/embed/IGMO54IIAAQ?si=Uv6n5nQENLddRKKV`}
                allowFullScreen
                style={{ borderRadius: '16px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
          </Box>
          </Center>
        </WithAnimation>
        <WithAnimation left>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="12px 32px 0 32px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={`https://cutt.ly/ResepsiZhidanAnnisa`} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="32px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                {`Live Reception`}
              </Button>
            </Link>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);